import React from 'react';
import { PageProps } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';

import { css, Theme } from '@emotion/react';

import Layout from '../layouts/Layout';
import FirstView from '@/components/organisms/common/FirstView';
import OverviewBand from '@/components/molecules/works/OverviewBand';
import Renovation from '@/components/organisms/works/Renovation';
import Architecture from '@/components/organisms/works/Architecture';
import CivilEngineering from '@/components/organisms/works/CivilEngineering';
import BottomMenu from '@/components/organisms/common/BottomMenu';
import ContactLinkBlock from '@/components/organisms/common/ContactLinkBlock';
import Seo from '@/components/organisms/common/Seo';

const FirstViewImage = (theme: Theme) => css({
  height: '25vh',
  [theme.breakpoints.down('xs')]: {
    height: '20vh',
  }
});

const WorksPage: React.VFC<PageProps> = () => {
  return (
    <Layout>
      <Seo
        title="施工実績"
        description="株式会社 富士見工務店のリフォーム、建築、土木の施工実績について、ご紹介します。"
      />
      <FirstView
        titleEn="WORKS"
        title="施工実績"
        titlePosition="right"
      >
        <StaticImage
          alt="WorksImage"
          src="../images/firstviews/works.jpg"
          css={FirstViewImage}
          layout="fullWidth"
        />
      </FirstView>
      <OverviewBand />
      <Architecture />
      <Renovation />
      <CivilEngineering />
      <ContactLinkBlock />
      <BottomMenu />
    </Layout>
  );
};

export default WorksPage;
