import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

import { css, Theme } from '@emotion/react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import InfoTypeDisplay from '@/components/molecules/common/InfoTypeDisplay';
import WorkCard from '@/components/molecules/works/WorkCard';

const imageAdjust = css({
  height: '100%',
});

const decorationImageBox = (theme: Theme) => css({
  height: '30vh',
  backgroundColor: theme.palette.background.default,
});

const decorationImage = css({
  height: '100%',
  opacity: 0.6
});

const Renovation: React.VFC = () => {
  return (
    <section id="renovation">
      <Grid container>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <InfoTypeDisplay
            backgroundColor="secondary"
            titleTextEn={['Renovation']}
            titleText="リフォーム施工例"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <WorkCard
            title="リフォーム改修工事"
            desc="鴻巣市H様　室内改修工事"
            color="dark"
          >
            <StaticImage
              alt="renovationImage01"
              src="../../../images/renovation/image01.jpg"
              css={imageAdjust}
            />
          </WorkCard>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <WorkCard
            title="駐輪場設置工事"
            desc="鴻巣市H様　駐輪場設置工事"
            color="dark"
          >
            <StaticImage
              alt="renovationImage02"
              src="../../../images/renovation/image02.jpg"
              css={imageAdjust}
            />
          </WorkCard>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <WorkCard
            title="防水工事"
            desc="三芳町S様　防水工事"
            color="dark"
          >
            <StaticImage
              alt="renovationImage03"
              src="../../../images/renovation/image03.jpg"
              css={imageAdjust}
            />
          </WorkCard>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <WorkCard
            title="リフォーム改修工事"
            desc="三芳町S様　浴槽改修工事"
            color="dark"
          >
            <StaticImage
              alt="renovationImage04"
              src="../../../images/renovation/image04.jpg"
              css={imageAdjust}
            />
          </WorkCard>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <WorkCard
            title="リフォーム改修工事"
            desc="志木市O様　内部改修工事"
            color="dark"
          >
            <StaticImage
              alt="renovationImage05"
              src="../../../images/renovation/image05.jpg"
              css={imageAdjust}
            />
          </WorkCard>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <WorkCard
            title="外壁改修工事"
            desc="志木市S様　外壁改修工事"
            color="dark"
          >
            <StaticImage
              alt="renovationImage06"
              src="../../../images/renovation/image06.jpg"
              css={imageAdjust}
            />
          </WorkCard>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <WorkCard
            title="外壁改修工事"
            desc="川越市S様　外壁改修工事"
            color="dark"
          >
            <StaticImage
              alt="renovationImage07"
              src="../../../images/renovation/image07.jpg"
              css={imageAdjust}
            />
          </WorkCard>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <WorkCard
            title="床貼替工事"
            desc="富士見市H様　床貼替工事"
            color="dark"
          >
            <StaticImage
              alt="renovationImage08"
              src="../../../images/renovation/image08.jpg"
              css={imageAdjust}
            />
          </WorkCard>
        </Grid>
          <Grid
            item xs={12} sm={6} md={4} lg={9}
            sx={{
              display: {
                xs: 'none',
                sm: 'block',
                md:'none',
                lg:'block'
              }
            }}
          >
            <Box css={decorationImageBox}>
              <StaticImage
                alt="inWorksRenovation01"
                src="../../../images/decoration/inWorksRenovation01.jpg"
                layout="fullWidth"
                css={decorationImage}
              />
            </Box>
          </Grid>
      </Grid>
    </section>
  );
};

export default Renovation;
