import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

import { css, Theme } from '@emotion/react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import InfoTypeDisplay from '@/components/molecules/common/InfoTypeDisplay';
import WorkCard from '@/components/molecules/works/WorkCard';

const imageAdjust = css({
  height: '100%',
});

const decorationImageBox = (theme: Theme) => css({
  height: '30vh',
  backgroundColor: theme.palette.background.default,
});

const decorationImage = css({
  height: '100%',
  opacity: 0.6
});

const Architecture: React.VFC = () => {
  return (
    <section id="architecture">
      <Grid container>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <InfoTypeDisplay
            backgroundColor="primary"
            titleTextEn={['Architecture']}
            titleText="建築施工例"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <WorkCard
            title="大規模改修工事"
            desc="市立水谷小学校北校舎大規模改造工事（１）"
            color="light"
          >
            <StaticImage
              alt="architectureImage12"
              src="../../../images/architecture/image12.jpg"
              layout="fullWidth"
              css={imageAdjust}
            />
          </WorkCard>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <WorkCard
            title="大規模改修工事"
            desc="市立水谷小学校北校舎大規模改造工事（２）"
            color="light"
          >
            <StaticImage
              alt="architectureImage13"
              src="../../../images/architecture/image13.jpg"
              layout="fullWidth"
              css={imageAdjust}
            />
          </WorkCard>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <WorkCard
            title="大規模改修工事"
            desc="刑事施設改修工事"
            color="light"
          >
            <StaticImage
              alt="architectureImage14"
              src="../../../images/architecture/image14.jpg"
              layout="fullWidth"
              css={imageAdjust}
            />
          </WorkCard>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <WorkCard
            title="大規模改修工事"
            desc="市立南畑小学校プール改修工事"
            color="light"
          >
            <StaticImage
              alt="architectureImage17"
              src="../../../images/architecture/image17.JPG"
              layout="fullWidth"
              css={imageAdjust}
            />
          </WorkCard>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <WorkCard
            title="大規模改修工事"
            desc="市立富士見特別支援学校大規模改造建築工事"
            color="light"
          >
            <StaticImage
              alt="architectureImage16"
              src="../../../images/architecture/image16.JPG"
              layout="fullWidth"
              css={imageAdjust}
            />
          </WorkCard>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <WorkCard
            title="大規模改修工事"
            desc="別所雨水ポンプ場建設工事"
            color="light"
          >
            <StaticImage
              alt="architectureImage15"
              src="../../../images/architecture/image15.jpg"
              layout="fullWidth"
              css={imageAdjust}
            />
          </WorkCard>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <WorkCard
            title="公共施設新築工事"
            desc="富士見市立南畑第２放課後児童クラブ建設工事"
            color="light"
          >
            <StaticImage
              alt="architectureImage18"
              src="../../../images/architecture/image18.jpg"
              layout="fullWidth"
              css={imageAdjust}
            />
          </WorkCard>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <WorkCard
            title="大規模改修工事"
            desc="ふじみ野市立さぎの森小学校体育館改修工事"
            color="light"
          >
            <StaticImage
              alt="architectureImage01"
              src="../../../images/architecture/image01.jpg"
              layout="fullWidth"
              css={imageAdjust}
            />
          </WorkCard>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <WorkCard
            title="公共施設新築工事"
            desc="ふじみ野市立大井子育て支援センター建設工事"
            color="light"
          >
            <StaticImage
              alt="architectureImage02"
              src="../../../images/architecture/image02.jpg"
              layout="fullWidth"
              css={imageAdjust}
            />
          </WorkCard>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <WorkCard
            title="RCマンション・事務所"
            desc="ホワイトロード新築工事"
            color="light"
          >
            <StaticImage
              alt="architectureImage03"
              src="../../../images/architecture/image03.jpg"
              css={imageAdjust}
            />
          </WorkCard>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <WorkCard
            title="大規模改修工事"
            desc="所沢航空記念公園格納庫及び発祥記念館改修工事"
            color="light"
          >
            <StaticImage
              alt="architectureImage04"
              src="../../../images/architecture/image04.jpg"
              css={imageAdjust}
            />
          </WorkCard>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <WorkCard
            title="警察・消防施設"
            desc="西入間警察署　坂戸駅前交番新築工事"
            color="light"
          >
            <StaticImage
              alt="architectureImage05"
              src="../../../images/architecture/image05.jpg"
              css={imageAdjust}
            />
          </WorkCard>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <WorkCard
            title="RCマンション・事務所"
            desc="第７富士見ビル新築工事"
            color="light"
          >
            <StaticImage
              alt="architectureImage06"
              src="../../../images/architecture/image06.jpg"
              css={imageAdjust}
            />
          </WorkCard>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <WorkCard
            title="警察・消防施設"
            desc="富士見市消防団第８分団車庫新築工事"
            color="light"
          >
            <StaticImage
              alt="architectureImage07"
              src="../../../images/architecture/image07.jpg"
              css={imageAdjust}
            />
          </WorkCard>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <WorkCard
            title="増築工事"
            desc="富士見市立関沢小学校増築工事"
            color="light"
          >
            <StaticImage
              alt="architectureImage08"
              src="../../../images/architecture/image08.jpg"
              css={imageAdjust}
            />
          </WorkCard>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <WorkCard
            title="新築工事"
            desc="富士見市立中央公園交流施設建設工事"
            color="light"
          >
            <StaticImage
              alt="architectureImage09"
              src="../../../images/architecture/image09.jpg"
              css={imageAdjust}
            />
          </WorkCard>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <WorkCard
            title="大規模改修工事"
            desc="富士見市立南畑小学校大規模改修工事"
            color="light"
          >
            <StaticImage
              alt="architectureImage10"
              src="../../../images/architecture/image10.jpg"
              css={imageAdjust}
            />
          </WorkCard>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <WorkCard
            title="刑事施設"
            desc="法務省　川越少年刑務所鍛錬所設置工事"
            color="light"
          >
            <StaticImage
              alt="architectureImage11"
              src="../../../images/architecture/image11.jpg"
              css={imageAdjust}
            />
          </WorkCard>
        </Grid>
        <Grid
          item xs={12} sm={6} md={8} lg={3}
          sx={{
            display: {
              xs: 'none',
              sm: 'block',
              md:'block',
              lg:'block'
            }
          }}
        >
          <Box css={decorationImageBox}>
            <StaticImage
              alt="inWorksRenovation01"
              src="../../../images/decoration/inWorksArchitecture01.jpg"
              layout="fullWidth"
              css={decorationImage}
            />
          </Box>
        </Grid>
      </Grid>
    </section>
  );
};

export default Architecture;
